import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import Index from '@/views/index'
import Privacy from '@/views/privacy'
import TermsOfService from '@/views/termsOfService'
import MembershipTerms from '@/views/MembershipTerms'
import Transition from './context/translate';

function App() {
    return (
        <Transition>
             <Router>
                <Switch>
                    <Route component={Index} path="/" exact></Route>
                    <Route component={Privacy} path="/privacy" exact></Route>
                    <Route component={TermsOfService} path="/termsOfService" exact></Route>
                    <Route component={MembershipTerms} path="/membershipTerms" exact></Route>
                    <Route render={() => <div>Not Found</div>} />
                </Switch>
            </Router>
        </Transition>
    );
}
export default App;
