import React, { useContext } from 'react'
import { TranslateContext } from '../context/translate'
import dataRule from "./components/termOfServiceText";
import config from "../utils/config";
const Page = (props) => {
    const tranlsate=useContext(TranslateContext);
    let logoName=config.logoName || 'logo'
    return (
        <div className="page pad-lg pt40 pb60">
            <div className="wrapper">
                <div className="logo">
                    <img src={require(`@/assets/images/${logoName}.png`).default} alt="" />
                </div>
                <div className="title fz40">{config.appName} Membership Terms of Use</div>
            </div>
            <div className="f-line"></div>

            <div className="wrap fz24 lh38 mt40">
               <p>
               Welcome to the {config.appName} Membership Terms of Use ("Terms") of {config.appName}. These Terms are between you and {config.appName} and/or its affiliates ("{config.appName}" or "Us") and govern our respective rights and obligations. Please note that your use of the {config.appName} APP and your Membership subscription are also governed by the agreements listed and linked to below, as well as all other applicable terms, conditions, limitations, and requirements on the {config.appName} APP, all of which (as changed over time) are incorporated into these Terms. If you sign up for a Membership subscription, you accept these terms, conditions, limitations and requirements. If you do not agree to these terms, you will not be able to access the subscription content.
               </p>
                <p className={'fw400'}>1. Account
                    If you do not have an {config.appName} account, you need to create an account on {config.appName} to use the service.</p>
                <p className="fw400">2. Subscription</p>
                <p>(1)Your membership takes effect 24 hours after the transaction. Once it takes effect, you’ll receive coins matched with the purchase price accordingly.</p>
                <p>(2)The extra coins will be issued to your {config.appName} account when you log in to our app every day. But you can’t claim them once your membership expires.</p>
                <p>(3)Subscription Cycle: Weekly/Monthly Payment Plans are auto-renewal services that can be canceled anytime.</p>
                <p>(4)Payment: Once you’ve subscribed to a membership and successfully paid for it, you can view the order information by accessing the purchase records on the membership page.</p>
                <p>(5) Renewal: Renewal fees will be deducted via the user's App Store’s account when the user's membership expires within 24 hours. And the subscription cycle will be extended after the successful deduction.</p>
                <p>(6)Auto-Renewal Cancellation: Open Settings on iPhone ——Click iTunes Store and App Store——Click Apple ID——Click View Apple ID——Click Account——Click Subscription ——Select {config.appName} Membership and Cancel.</p>

                <p className="fw400">3. Fees and renewals</p>
                <p>The subscription price is shown in U.S. dollars (United States) or, if possible, in the equivalent local currency. The currency in which you are charged for subscription  depends on the billing country in which your payment method is located. You acknowledge that {config.appName} cannot control any currency exchange or foreign transaction fees that your card issuer or bank may charge you, and you agree not to require {config.appName} or its affiliates, contractors, employees, agents or third-party partners, licensors or the supplier to afford any such expenses.</p>
                <p>You will pay the Unlimited membership subscription fee ("subscription fee") at the current rate at the beginning of the payment portion of the subscription and for a fixed period thereafter. We will use the payment information you provide to automatically charge you on a regular basis on the calendar day at the beginning of the subscription payment cycle. If your subscription starts on a day that is not included in a given month, we may charge you on a certain day of the applicable month or other days we deem appropriate. For example, if you choose monthly Unlimited membership and you start subscribing on January 31st, your next payment date may be February 28th, and your Unlimited membership subscription fee will be billed on that date.</p>
                <p>If your Unlimited subscription membership plan involves regular payment, unless you notify us before charging that you want to cancel or do not want to automatically renew your membership or subscription, you understand and agree that your {config.appName} Unlimited membership or subscription will automatically continue , And you authorize us (without notifying you unless required by applicable law) to use any payment method we have recorded for you to collect membership fees and any taxes applicable at that time.</p>
<p>If all the payment methods we have on file for you are refused to pay your Unlimited membership fee, your Unlimited membership will be cancelled unless you provide us with a new payment method. If you provide us with a new payment method and successfully pay before your Unlimited membership is cancelled, your new Unlimited membership date will be based on the original renewal date instead of the date of the successful charge.</p>

           <p className="fw400">4. Cancel subscription</p>
                <p>You can cancel your Unlimited membership subscription service by visiting your app store and adjusting your subscription settings. If you cancel the Unlimited membership subscription service, after your Unlimited membership expires, you will no longer be able to enjoy Unlimited membership reading rights. You understand and agree that any Unlimited membership fees and subscription fees you have paid cannot be cancelled and cannot be refunded.</p>


                <p className="fw400">5.Subscription restrictions</p>
                <p>We reserve the right to accept or decline Membership subscriptions and subscriptions at our own discretion. You may not transfer or assign your Membership subscription or any {config.appName} benefits. We may take necessary reasonable measures to prevent fraud, including limiting the amount of content that can be accessed from the program at any time.</p>

            <p className="fw400">6.Content restrictions</p>
    <p>Your access and use of subscription content are subject to the following restrictions:</p>
                <p>(1)Subscription content is provided for personal non-commercial use only through {config.appName} mobile app ("App");</p>
                <div>(2)You may not <br/>
                   <p style={{paddingLeft:'10px'}}>
                       (i) transmit, copy or display content unless permitted by this agreement; <br/>
                       (ii) sell, rent, distribute or broadcast any content; <br/>
                       (iii) publicly display or display subscription content, delete any ownership of subscription content Statement or label;<br/>
                       (iv) attempt to disable, bypass, modify, destroy or otherwise circumvent any digital rights management or other protection system applied to content or used as part of the service;<br/>
                       (v) share accounts that allow access to subscribed content; <br/>
                       (vi) Use the service or content for any commercial or illegal purpose.
                   </p>
                </div>
                <p>In addition to the foregoing, the condition for your access to the subscription content is to pay the subscription fee in time: if your subscription is cancelled, invalidated or terminated due to non-payment, you will not be able to access the subscription content.</p>
<p>{config.appName} cannot guarantee the availability or available time of specific content.</p>
                <p>We sometimes provide certain customers with various trials or other promotional activities for Membership subscriptions. Unless otherwise stated in the promotional offers, these Membership subscriptions are subject to terms and conditions.
                </p>

                <p className="fw400">7.Term and Termination</p>
                <p>This agreement starts from the date you accept its terms and lasts until it terminates. We may terminate this agreement (including your subscription and/or account) at any time without notice. If we do, we will refund you proportionally based on the remaining days of your Unlimited membership, unless we decide to terminate your Unlimited membership due to your violation of these terms or any applicable laws, as well as fraud or abuse of membership Qualify or harm our or other users’ interests. Our failure to insist or force you to strictly abide by these terms does not constitute a waiver of any of our rights.</p>

           <p className="fw400">8. Disputes; limitation of liability</p>
                <p>Any disputes or claims arising from or related to these terms or your membership are subject to dispute resolution, governing law, disclaimer and limitation of liability, and all other terms in the {config.appName} terms of use. Without limiting the disclaimer and limitation of liability in the {config.appName} terms of use, our total liability under these terms will not exceed the total membership fee you’ve paid within twelve months before the claim.</p>
<p className="fw400">9. Take effect</p>
                <p>This agreement will take effect on the date of the renewal time indicated on the homepage.</p>
                <p className="fw400">{config.appName} Team</p>
            </div>
        </div>
    )
}
export default Page
