import React from 'react'
import config from '@/utils/config.js'
const Logo=() =>{
    let logoName=config.logoName || 'logo'
    return (
        <div className="flex items-center">
            <img src={require(`@/assets/images/${logoName}.png`).default} className="w60 rad14" alt="" />
        </div>
    )
}

export default Logo

